<script setup lang="ts">
import type { EffectsTypewriter } from '#components'
import { animate, scroll } from 'motion'

defineProps<{
  inView: boolean
}>()

defineSlice({
  name: { label: 'Studio Demo', group: 'Demos', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesStudioDemo.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const el = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)
const fullPane = ref<HTMLElement | null>(null)
const pageNavigation = ref<HTMLElement | null>(null)
const propertiesPanel = ref<HTMLElement | null>(null)
const pageEditor = ref<HTMLElement | null>(null)
const degreeRange = [18, 0]
const scaleRange = [1.5, 1]
const translateYRange = [30, 0]
const progress = ref(0)
const contentsFixed = ref(false)
const fixedBottom = ref(false)

onMounted(() => {
  el.value = document.body as HTMLElement

  scroll(
    (p: any) => {
      progress.value = p.y.progress

      if (progress.value > 0.25) {
        contentsFixed.value = true
      }
      else {
        contentsFixed.value = false
      }

      if (progress.value === 1) {
        fixedBottom.value = true
      }
      else {
        fixedBottom.value = false
      }
    },
    {
      target: container.value,
      offset: ['start end', 'end end'],
    },
  )
})

const currentAngle = computed(() => {
  // Ensure the scaling only starts when progress is within [0.25, 0.75]
  if (progress.value < 0.25)
    return degreeRange[0]
  if (progress.value > 0.75)
    return degreeRange[1]

  // Map progress from [0.25, 0.75] to [0, 1]
  const normalizedProgress = (progress.value - 0.25) / (0.75 - 0.25)

  // Scale the normalized progress to the given range
  return degreeRange[0] + normalizedProgress * (degreeRange[1] - degreeRange[0])
})

const fullPaneStyles = computed(() => {
  return {
    transform: `perspective(75em) rotateX(${currentAngle.value}deg)`,
  }
})

const currentScale = computed(() => {
  // Ensure the scaling only starts when progress is within [0.25, 0.75]
  if (progress.value < 0.25)
    return scaleRange[0]
  if (progress.value > 0.75)
    return scaleRange[1]

  // Map progress from [0.25, 0.75] to [0, 1]
  const normalizedProgress = (progress.value - 0.25) / (0.75 - 0.25)

  // Scale the normalized progress to the given range
  return scaleRange[0] + normalizedProgress * (scaleRange[1] - scaleRange[0])
})

const currentTranslateY = computed(() => {
  // Ensure the scaling only starts when progress is within [0.25, 0.75]
  if (progress.value < 0.25)
    return translateYRange[0]
  if (progress.value > 0.75)
    return translateYRange[1]

  // Map progress from [0.25, 0.75] to [0, 1]
  const normalizedProgress = (progress.value - 0.25) / (0.75 - 0.25)

  // Scale the normalized progress to the given range
  return translateYRange[0] + normalizedProgress * (translateYRange[1] - translateYRange[0])
})

const panelOpacityValue = computed(() => {
  // Ensure the opacity only starts when progress is within [0.25, 0.75]
  if (progress.value < 0.25)
    return 0
  if (progress.value > 0.75)
    return 1

  // Map progress from [0.25, 0.75] to [0, 1]
  const normalizedProgress = (progress.value - 0.25) / (0.75 - 0.25)

  // Scale the normalized progress to the given range
  return 0 + normalizedProgress * (1 - 0)
})

const panelStyles = computed(() => {
  return {
    transform: `scale(${currentScale.value}) translateY(${currentTranslateY.value}px)`,
    opacity: panelOpacityValue.value,
  }
})

// Calculate the current scale percentage of the page editor
const pageEditorScale = computed(() => {
  const maxHeight = 900
  const currentHeight = pageEditor.value?.clientHeight || maxHeight
  const scaleHeight = currentHeight / maxHeight
  return scaleHeight
})

const mobileContentRef = ref<InstanceType<typeof EffectsTypewriter> | null>(null)
const desktopContentRef = ref<InstanceType<typeof EffectsTypewriter> | null>(null)
const editorContentRef = ref<InstanceType<typeof EffectsTypewriter> | null>(null)

watch(() => progress.value, () => {
  if (progress.value >= 0.90 && mobileContentRef.value && desktopContentRef.value && editorContentRef.value) {
    mobileContentRef.value.beginAnimation()
    desktopContentRef.value.beginAnimation()
    editorContentRef.value.beginAnimation()
  }
})

const pageNavigationPanelWidth = computed(() => {
  if (progress.value >= 0.85)
    return 330 * pageEditorScale.value

  return 240 * pageEditorScale.value
})

const propertiesPanelWidth = computed(() => {
  if (progress.value >= 0.85)
    return 320 * pageEditorScale.value

  return 225 * pageEditorScale.value
})
</script>

<template>
  <div ref="container" class="relative lg:min-h-[400vh] max-w-screen lg:overflow-hidden ">
    <video
      class="mb-24 lg:hidden"
      src="/videos/editing-demo.mp4"
      autoplay
      loop
      muted
      playsinline
    />

    <div
      class="transition-all duration-1000 lg:h-screen pointer-events-none"
      :class="{
        'relative w-full h-full': !contentsFixed,
        'lg:fixed lg:inset-0': contentsFixed && !fixedBottom,
        'lg:absolute lg:bottom-0 lg:left-0 lg:right-0': contentsFixed && fixedBottom,
      }"
    >
      <div class="hidden lg:block absolute inset-0 z-0 ">
        <EffectsRandomlyMovingSvg v-if="inView" class="absolute inset-0 z-0 opacity-30" />
      </div>
      <div class="hidden lg:flex absolute  items-center justify-center  inset-0 px-24">
        <div
          ref="fullPane" class="w-full max-w-[1600px] relative z-10 flex justify-center aspect-video" :style="fullPaneStyles"
        >
          <div class="flex justify-center">
            <!-- Navigation Panel -->
            <div class="transition-all duration-50 ease-out h-full" :style="{ width: `${pageNavigationPanelWidth}px` }">
              <div ref="pageNavigation" class="overflow-hidden h-full rounded-l-md" :style="panelStyles">
                <NuxtImg src="/imgs/interface-screenshots/pages-editor-page-navigator-panel.png" alt="Navigator Panel for the Voix Studio Editor" class="object-cover w-full h-full object-left" height="1240" />
              </div>
            </div>

            <!--  Main Screen -->
            <div
              class="full-pane transition-all duration-[1.5s] ease-out" :class="{
                'w-1/2': progress < 0.85,
                'w-full': progress >= 0.85,
              }"
            >
              <div ref="pageEditor" class="relative overflow-hidden h-full">
                <NuxtImg src="/imgs/interface-screenshots/pages-editor-desktop-and-mobile.png" alt="Screen Preview for the Voix Studio Editor" class="object-cover w-full h-full object-left" width="1824" height="1240" />

                <!-- Desktop Content -->
                <div
                  class="absolute transition-all"
                  :class="{
                    'opacity-0 duration-50': progress < 0.90,
                    'opacity-100 delay-[1.5s] duration-300': progress >= 0.90,
                  }"
                  :style="{
                    top: `${190 * pageEditorScale}px`,
                    left: `${93 * pageEditorScale}px`,
                    width: `${450 * pageEditorScale}px`,
                    height: `${200 * pageEditorScale}px`,
                  }"
                >
                  <div
                    class="font-sans text-xs w-full font-light leading-[1.8em]" :style="{
                      'font-size': `${11 * pageEditorScale}px`,
                      'width': `${450 * pageEditorScale}px`,
                      'height': `${200 * pageEditorScale}px`,
                    }"
                  >
                    <EffectsTypewriter
                      ref="desktopContentRef"
                      :strings="[
                        `Welcome to our showcase of recent projects, where we proudly present the work we've crafted alongside our incredible clients. Each project tells a unique story of collaboration, creativity, and technological expertise, reflecting our commitment to delivering results that resonate. Here, you'll find links to in-depth articles that dive into the details behind each project, exploring the challenges faced and the solutions created. We invite you to explore these stories and see how LogicBomb Media is driving digital excellence across industries.`,
                      ]"
                      :start-paused="true"
                    />
                  </div>
                </div>

                <!-- Mobile Content -->
                <div
                  class="absolute transition-all  "
                  :class="{
                    'opacity-0 duration-50': progress < 0.90,
                    'opacity-100 delay-[1.5s] duration-300': progress >= 0.90,
                  }"
                  :style="{
                    top: `${220 * pageEditorScale}px`,
                    right: `${170 * pageEditorScale}px`,
                    width: `${100 * pageEditorScale}px`,
                    height: `${400 * pageEditorScale}px`,
                  }"
                >
                  <div
                    class="font-sans text-xs w-full font-light leading-[1.8em]" :style="{
                      'font-size': `${12 * pageEditorScale}px`,
                      'width': `${190 * pageEditorScale}px`,
                      'height': `${400 * pageEditorScale}px`,
                    }"
                  >
                    <EffectsTypewriter
                      ref="mobileContentRef"
                      :strings="[
                        `Welcome to our showcase of recent projects, where we proudly present the work we've crafted alongside our incredible clients. Each project tells a unique story of collaboration, creativity, and technological expertise, reflecting our commitment to delivering results that resonate. Here, you'll find links to in-depth articles that dive into the details behind each project, exploring the challenges faced and the solutions created. We invite you to explore these stories and see how LogicBomb Media is driving digital excellence across industries.`,
                      ]"
                      :start-paused="true"
                    />
                  </div>
                </div>

                <!-- Editor -->
                <div
                  class="absolute rounded-sm overflow-hidden duration-300"
                  :class="{
                    'opacity-0 duration-50': progress < 0.90,
                    'opacity-100 delay-[1.5s] duration-300': progress >= 0.90,
                  }"
                  :style="{
                    top: `${500 * pageEditorScale}px`,
                    right: `${0 * pageEditorScale}px`,
                    width: `${400 * pageEditorScale}px`,
                    height: `${295 * pageEditorScale}px`,
                  }"
                >
                  <div class="absolute inset-0">
                    <NuxtImg src="/imgs/interface-screenshots/pages-editor-wysiwyg.jpg" alt="Wysiwyg Editor" class="object-cover w-full h-full object-left" width="400" height="295" />
                  </div>

                  <div
                    class="relative z-10 font-sans text-xs w-full font-light leading-[1.8em] text-stone-800" :style="{
                      'padding': `${70 * pageEditorScale}px ${80 * pageEditorScale}px 0 ${30 * pageEditorScale}px`,
                      'font-size': `${9 * pageEditorScale}px`,
                      'width': `${450 * pageEditorScale}px`,
                      'height': `${200 * pageEditorScale}px`,
                    }"
                  >
                    <EffectsTypewriter
                      ref="editorContentRef"
                      :strings="[
                        `Welcome to our showcase of recent projects, where we proudly present the work we've crafted alongside our incredible clients. Each project tells a unique story of collaboration, creativity, and technological expertise, reflecting our commitment to delivering results that resonate. Here, you'll find links to in-depth articles that dive into the details behind each project, exploring the challenges faced and the solutions created. We invite you to explore these stories and see how LogicBomb Media is driving digital excellence across industries.`,
                      ]"
                      :start-paused="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Properties Panel -->
            <div class="transition-all duration-50 ease-out h-full" :style="{ width: `${propertiesPanelWidth}px` }">
              <div ref="propertiesPanel" class="overflow-hidden h-full rounded-r-md" :style="panelStyles">
                <NuxtImg src="/imgs/interface-screenshots/pages-editor-properties-panel.png" alt="Navigator Panel for the Voix Studio Editor" class="object-cover w-full h-full object-left" height="1240" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative lg:absolute z-20 lg:bottom-0 lg:left-0 lg:right-0 flex justify-center pb-16 transition-all duration-500 ease-out" :class="{
          'lg:opacity-0 lg:translate-y-24': progress < 0.9,
          'lg:opacity-100': progress >= 0.9,
        }"
      >
        <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-black h-[400px]" />
        <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-voix-alt1 via-voix-alt1 h-[400px]" />
        <div class="relative z-10 max-w-7xl px-12 grid md:grid-cols-3 text-white gap-y-12 gap-x-12 lg:gap-x-32 font-sans">
          <div>
            <div class="text-lg font-medium text-neutral-50">
              Add and Remove Slices
            </div>
            <div class="pt-1 text-sm text-neutral-200 font-light">
              With Voix, you can effortlessly drop fully custom designed slices onto a page, creating dynamic, fully brand-approved layouts that bring your content to life.
            </div>
          </div>
          <div>
            <div class="text-lg font-medium text-neutral-50">
              View Multiple Breakpoints at Once
            </div>
            <div class="pt-1 text-sm text-neutral-200 font-light">
              Voix streamlines responsive design by enabling you to edit multiple breakpoints in a single view, ensuring your content looks perfect across all devices.
            </div>
          </div>
          <div>
            <div class="text-lg font-medium text-neutral-50">
              Just the right amount of control
            </div>
            <div class="pt-1 text-sm text-neutral-200 font-light">
              Voix ensures just the right amount of control by allowing designers to define only the essential fields, empowering content managers to make updates while preserving the integrity of the design.
            </div>
          </div>
        </div>

      <!-- <div class="hidden fixed bottom-0 left-0 text-black">
        {{ pageEditorScale }}
      </div> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full-pane {
    // transform: perspective(75em) rotateX(5deg);
    box-shadow:
      rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;

}
</style>
